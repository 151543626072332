<template>
  <div id="our-history">
    <b-container class="base-container-x about-header-box" style="min-height:500px;background-color:#fff">
      <div v-if="loading == true" class="col-12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </div>
      <div class="banner-inner our-history text-center">
        <div v-if="loading == false" class="slideshow_caption">
          <div class="slideshow_inner_caption">
            <div class="slideshow_align_caption">
              <h1 class="text-uppercase">{{ $t('Our History') }}</h1>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loading == false" class="col-12">
        <ul class="timeline">
          <li><h5 class="tldate text-primary-light">1990 – 2010</h5></li>
          <li class="timeline-inverted">
            <div class="tl-circ"></div>
            <div class="timeline-panel">
              <div class="tl-heading">
              </div>
              <div class="tl-body">
                <p>{{ $t('WYA came up with the idea of gathering professional Yoga Masters and University Professors who have been organizing free yoga camps all over India. They were teaching yoga in many different places such as prisons, hospitals, army camps, universities and schools with the intention of sharing yoga knowledge with society.') }}</p>
              </div>
            </div>
          </li>
          <li><h5 class="tldate text-primary-light">2011 – 2014</h5></li>
          <li class="timeline-inverted">
            <div class="tl-circ"></div>
            <div class="timeline-panel">
              <div class="tl-heading">
              </div>
              <div class="tl-body">
                <p>{{ $t('Once yoga started to become more and more popular around the world, our masters decided to create the standards for yoga professionals and spread the Indian tradition of yoga to other countries worldwide. They were committed to stand up as an organization that works for the benefit of the whole society.') }}</p>
                <p>{{ $t('On 17th November 2011, World Yoga Alliance officially registered and recognized by the Ministry of Human Resource Development (NCT) in New Delhi, which functions under the Central Government of India.') }}</p>
              </div>
            </div>
          </li>
          <li><h5 class="tldate text-primary-light">2015 – 2019</h5></li>
          <li class="timeline-inverted">
            <div class="tl-circ"></div>
            <div class="timeline-panel">
              <div class="tl-heading">
              </div>
              <div class="tl-body">
                <p>{{ $t('Narendra Modi, Indian Prime Minister, also supported our idea of spreading the Indian traditions of yoga around the world, and therefore he launched the International Day of Yoga on 21st of June in 2015 to celebrate yoga unity all around the world.') }}</p>
                <p>{{ $t('Within a few years, WYA Organization has grown larger and now we have members over 80 countries worldwide.') }}</p>
              </div>
            </div>
          </li>
          <li><h5 class="tldate text-primary-light">{{ $t('2020 – Present') }}</h5></li>
          <li class="timeline-inverted">
            <div class="tl-circ"></div>
            <div class="timeline-panel">
              <div class="tl-heading">
              </div>
              <div class="tl-body">
                <p>{{ $t('Since the Covid-19 pandemic situation, we are aware that we all need to adapt ourselves into this new normal. As a result, we started to support an online training because we do believe that effective yoga teacher training can be done not only by teaching face to face but we also see the possibilities in online teaching with the same high-quality of educational standards. We have been supporting our members to maintain the highest quality of yoga education both online and onsite trainings. So far several local yoga studios  have successfully became an international yoga schools with the support from World Yoga Alliance official team.') }}</p>
                <p>{{ $t('We started to establish an Online International Office that allows our staff to work from home and maintain their social distancing during the pandemic.') }}</p>
                <p>{{ $t('We still continue doing our best to support our members and provide services to our yoga community.') }}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'OurBoard',
  title: ' | Our Board',
  data () {
    return {
      loading: true
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>

<style scoped>
  #our-history {
  /* Lock Text */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
body { background: #333; }

img { border: 0; max-width: 100%; }

.page-header h1 {
  font-size: 3.26em;
  text-align: center;
  color: #efefef;
  text-shadow: 1px 1px 0 #000;
}

/** timeline box structure **/
.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}

.timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #eee;
  left: 25%;
  /* margin-left: -1.5px; */
}

.tldate {
  position: absolute;
  top: 50px;
  left: 0%;
  display: flex;
  justify-content: flex-end;
  /* display: block;
  width: 200px; */
  background: white;
  /* border: 3px solid #212121; */
  margin: 0 auto;
  padding: 3px 0;
  /* font-weight: bold; */
  /* text-align: center; */
  /* -webkit-box-shadow: 0 0 11px rgba(0,0,0,0.35); */
}

.timeline li {
  margin-bottom: 25px;
  position: relative;
}

.timeline li:before, .timeline li:after {
  content: " ";
  display: table;
}
.timeline li:after {
  clear: both;
}
.timeline li:before, .timeline li:after {
  content: " ";
  display: table;
}

/** timeline panels **/
.timeline li .timeline-panel {
  width: 70%;
  float: left;
  background: #fff;
  border: 1px solid #d4d4d4;
  padding: 20px;
  position: relative;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
}

/** panel arrows **/
.timeline li .timeline-panel:before {
  position: absolute;
  top: 26px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 15px solid #ccc;
  border-right: 0 solid #ccc;
  border-bottom: 15px solid transparent;
  content: " ";
}

.timeline li .timeline-panel:after {
  position: absolute;
  top: 27px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 14px solid #fff;
  border-right: 0 solid #fff;
  border-bottom: 14px solid transparent;
  content: " ";
}
.timeline li .timeline-panel.noarrow:before, .timeline li .timeline-panel.noarrow:after {
  top:0;
  right:0;
  display: none;
  border: 0;
}

.timeline li.timeline-inverted .timeline-panel {
  float: right;
}

.timeline li.timeline-inverted .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.timeline li.timeline-inverted .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}
/** timeline circle icons **/
.timeline li .tl-circ {
  position: absolute;
  top: 35px;
  left: 25%;
  /* text-align: center; */
  background: gray;
  color: #fff;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  /* line-height: 35px; */
  border: 3px solid gray;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  z-index: 100;
}

/** timeline content **/

.tl-heading h4 {
  margin: 0;
  color: #c25b4e;
}

.tl-body p, .tl-body ul {
  margin-bottom: 0;
}

.tl-body > p + p {
  margin-top: 5px;
}

/** media queries **/
@media (max-width: 991px) {
  .timeline li .timeline-panel {
    width: 70%;
  }
}

@media (max-width: 700px) {
  .page-header h1 { font-size: 1.8em; }
  ul.timeline:before {
    left: 40px;
  }

  .tldate {
    position: initial;
    top: none;
    left: none;
    width: 140px;

  }
  ul.timeline li .timeline-panel {
    width: calc(100% - 90px);
    width: -moz-calc(100% - 90px);
    width: -webkit-calc(100% - 90px);
  }
  ul.timeline li .tl-circ {
    top: 35px;
    left: 33px;
    margin-left: 0;

  }
  ul.timeline > li > .tldate {
    margin: 0;
  }

  ul.timeline > li > .timeline-panel {
    float: right;
  }

  ul.timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }

  ul.timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}
</style>
